import Head from "next/head";
import Header from "@/src/components/Layout/header/Header";
import MultiSearchInput from "@/src/components/Common/Inputs/MultiSearchInput";
import Container from "@/src/components/Layout/Container";
// import SliderSection from "../src/components/Pages/Home/SliderSection";
import FeatureCard from "../src/components/Common/Cards/FeatureCard";
import CompanyCard from "../src/components/Common/Cards/CompanyCard";
import BlogCard from "../src/components/Common/Cards/BlogCard";
import HomeTitle from "../src/components/Pages/Home/HomeTitle";
import HeroSection from "@/src/components/Pages/Home/HeroSection";
import FaqSection from "@/src/components/Pages/Home/FaqSection";
import AnimateLogoSection from "@/src/components/Pages/Home/AnimateLogoSection";
import TransportationSection from "@/src/components/Pages/Home/TransportationSection";
import Footer from "@/src/components/Layout/footer/Footer";
import { observer } from "mobx-react-lite";
import AdsCard from "@/src/components/Common/Cards/AdsCard";
import request from "@/utils/request";
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import {
  adsCardRestructure,
  blogCardRestructure,
  companyCardRestructure,
  slidersRestructure,
} from "@/utils/parser";
import { toast } from "@/utils/toast";
import Layout from "@/src/components/Layout/Layout";
import { FormProvider, useForm } from "react-hook-form";
import { useRouter } from "next/router";
import { cookies } from "next/headers";
import { useTranslation } from "next-i18next";

import dynamic from "next/dynamic";

const SliderSection = dynamic(() => import("../src/components/Pages/Home/SliderSection"), {ssr:false});

function Home(props) {
  const {
    sliders = [],
    adsUnique = [],
    adsPurchase = [],
    adsReadyToShip = [],
    adsSale = [],
    features = [],
    companies = [],
    shippingTypes = [],
    faqs = [],
    blogs = [],
    userInfo = {},
    categories=[],
    countries=[],
    isLogin=false
  } = props;

  const router = useRouter();
  const methods = useForm({
    mode: "onSubmit",
  });
  const { register, handleSubmit, errors, watch, formState, reset } = methods;
  const {t}=useTranslation("home");

  const onSubmitSearch = async (data) => {
    router.push({
      pathname: "/ads",
      query: {
        ...(data.search_key && data.search_key!="" &&{search_key:data.search_key}),
        ...(data.country_id && data.country_id!="" &&{country_id:data.country_id}),
        ...(data.category_id && data.category_id!="" &&{category_id:data.category_id}),
      },
    });
  };
  return (
    <>
      <Head>
        <title>{t("seo_title")}</title>
        <meta name="description" content={t("meta_description")} />
      </Head>
      <Layout>
        <section className="-mt-11">
          <Container>
            <FormProvider {...methods}>
              <form autoComplete="off" onSubmit={handleSubmit(onSubmitSearch)}>
                <MultiSearchInput categories={categories} countries={countries} className={"lg:mt-8"}></MultiSearchInput>
              </form>
            </FormProvider>
          </Container>
        </section>
        <section className="relative">
          <img
            src="/icons/SectionBack1.svg"
            className="absolute left-0 top-0 -z-10 w-screen select-none" alt="siraaf-bg-ribbon"
          ></img>
          <Container>
            <HomeTitle></HomeTitle>
            <HeroSection isLogin={isLogin} userInfo={userInfo} items={sliders}></HeroSection>
          </Container>
        </section>
        <section>
          <Container>
            <SliderSection
              title={t("featured_ads")}
              items={adsUnique}
              compType={"ADS"}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                700: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                1248: {
                  slidesPerView: 4,
                  spaceBetween: 45,
                },
                2048: {
                  slidesPerView: 6,
                  spaceBetween: 45,
                },
              }}
            ></SliderSection>
          </Container>
        </section>
        <section className="relative">
          <img
            src="/icons/SectionBack2.svg"
            className="absolute left-0 -top-1/2 -z-10 w-screen select-none"
            alt="siraaf-bg-ribbon-2"
          ></img>
          <Container>
            <SliderSection
              title={t("purchase_requests")}
              items={adsPurchase}
              compType={"ADS"}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                700: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                1248: {
                  slidesPerView: 4,
                  spaceBetween: 45,
                },
                2048: {
                  slidesPerView: 6,
                  spaceBetween: 45,
                },
              }}
            ></SliderSection>
          </Container>
        </section>
        <section>
          <Container>
            <SliderSection
              title={t("ready_to_ship")}
              items={adsReadyToShip}
              compType={"ADS"}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                700: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                1248: {
                  slidesPerView: 4,
                  spaceBetween: 45,
                },
                2048: {
                  slidesPerView: 6,
                  spaceBetween: 45,
                },
              }}
            ></SliderSection>
          </Container>
        </section>
        <section className="relative">
          <img
            src="/icons/SectionBack3.svg"
            className="absolute left-0 -top-1/3 -z-10 w-screen select-none"
            alt="siraaf-bg-ribbon-3"
          ></img>
          <Container>
            <SliderSection
              title={t("sales_offers")}
              items={adsSale}
              compType={"ADS"}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                700: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                1248: {
                  slidesPerView: 4,
                  spaceBetween: 45,
                },
                2048: {
                  slidesPerView: 6,
                  spaceBetween: 45,
                },
              }}
            ></SliderSection>
          </Container>
        </section>
        <section>
          <Container>
            <SliderSection
              title={t("what_ever_you_want")}
              items={features}
              compType={"FEATURE"}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                700: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                1248: {
                  slidesPerView: 4,
                  spaceBetween: 45,
                },
                2048: {
                  slidesPerView: 5,
                  spaceBetween: 45,
                },
              }}
            ></SliderSection>
          </Container>
        </section>
        <section>
          <Container>
            <SliderSection
              title={t("the_most_reliable")}
              items={companies}
              compType={"COMPANY"}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                700: {
                  slidesPerView: 2,
                  spaceBetween: 25,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                1248: {
                  slidesPerView: 4,
                  spaceBetween: 45,
                },
                2048: {
                  slidesPerView: 5,
                  spaceBetween: 45,
                },
              }}
            ></SliderSection>
          </Container>
        </section>
        <section className="relative">
          <img
            src="/icons/SectionBack4.svg"
            className="absolute left-0 rtl:left-auto rtl:right-0 -top-1/4 -z-10 w-screen select-none"
            alt="siraaf-bg-ribbon-4"
          ></img>
          <Container>
            <AnimateLogoSection textColor={"black"} circleColor={"white"}></AnimateLogoSection>
            <TransportationSection
              items={shippingTypes}
            ></TransportationSection>
          </Container>
        </section>
        <section>
          <Container>
            <FaqSection items={faqs}></FaqSection>
          </Container>
        </section>
        <section>
          <Container>
            <SliderSection
              title={t("every_thing_you_need_to_learn")}
              items={blogs}
              compType={"BLOG"}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                700: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 45,
                },
                2048: {
                  slidesPerView: 6,
                  spaceBetween: 45,
                },
              }}
            ></SliderSection>
          </Container>
        </section>
      </Layout>
     
    </>
  );
}

export const getServerSideProps = async (context) => {
  let result = await request(
    {
      url: "/api/page/home",
      method: "POST",
    },
    {
      search_key: "",
      page_size: 10,
      current_page: 1,
    },
    context
  );
    
  //console.log("err",result)
  
  if (result && result.data) {
    // restructure data
    const sliders = slidersRestructure(result.data.sliders);
    const adsUnique = adsCardRestructure(result.data.ads_unique);
    const adsPurchase = adsCardRestructure(result.data.ads_purchase);
    const adsReadyToShip = adsCardRestructure(result.data.ads_ready);
    const adsSale = adsCardRestructure(result.data.ads_sales);
    const features = result.data.features;
    const companies = companyCardRestructure(result.data.companies);
    const shippingTypes = result.data.shipping_types;
    const faqs = result.data.faqs;
    const blogs = blogCardRestructure(result.data.blogs);
    const userInfo = result.data.web_user_info;
    const categories = result.data.ad_categories;
    const countries = result.data.countries;

    const { locale } = context;
    return {
      props: {
        ...(await serverSideTranslations(locale, ['common','header','footer','home','auth'])),
        sliders,
        adsUnique,
        adsPurchase,
        adsReadyToShip,
        adsSale,
        features,
        companies,
        shippingTypes,
        faqs,
        blogs,
        userInfo,
        categories,
        countries,
        isLogin:result?.isLogin
      },
    };
  } else {
    return {
      notFound: true,
    };
  }
};

export default Home;
