import React from 'react';
import { useTranslation } from 'next-i18next';

const HomeTitle = () => {
  const {t}=useTranslation("home");
  return (
    <div className='flex justify-center w-full items-center'>
        <div className='flex-initial lg:mr-4 xl:mr-16 rtl:lg:ml-4 rtl:xl:ml-16 rtl:mr-0'>
            <h1 className='text-4xl  text-black text-center lg:text-left rtl:lg:text-right font-open-sans rtl:font-dana-r my-4' dangerouslySetInnerHTML={{__html:t("hero_title")}}></h1>
        </div>
        <div className='lg:flex-grow lg:flex hidden'>
            <hr className="w-full h-0.5 my-8 bg-gradient-primary border-0"></hr>
        </div>
    </div>
  )
}

export default HomeTitle