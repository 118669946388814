import React from "react";
import ImageMultiSrc from "../../Common/Main/ImageMultiSrc";
import { useTranslation } from "next-i18next";

const TransportationSection = (props) => {
  const {items=[]}=props;
  const {t}=useTranslation("home");
  return (
    <div className="mb-16">
      <h4 className="font-open-sans rtl:font-dana-r lg:text-4xl text-lg font-light text-gray-dark text-center">
        {t("shipping_by_all_possible_methods")}
      </h4>
      <p className="w-full mt-5 font-open-sans rtl:font-dana-r text-sm text-center text-black ml-2 rtl:ml-auto rtl:mr-2"> {t("shipping_by_all_possible_methods_desc")}</p>
      <div className="lg:flex flex-col lg:flex-row lg:gap-x-40 mt-10">
        {items.map((item,idx) => (
          <div key={idx} className="lg:basis-1/3 mt-4 lg:mt-0">
            <div className="inline-flex items-center mb-6">
              <hr className="w-6 h-0.5 border-0 bg-blue"></hr>
              <h5 className="lg:text-3xl text-lg text-black ml-2 rtl:ml-auto rtl:mr-2">{item.title}</h5>
            </div>
            <ImageMultiSrc alt={"Siraaf-"+item.title} className="w-full rounded-lg h-52" src={process.env.NEXT_PUBLIC_ROOT_URL_OF_FILES + "/storage/" + item.image} ></ImageMultiSrc>
            {/* <div className="inline-flex  mt-8">
              <hr className="w-6 h-0.5 border-0 bg-black mt-2"></hr>
              <p className="font-open-sans rtl:font-dana-r text-sm text-justify text-black ml-2 rtl:ml-auto rtl:mr-2">{item.desc}</p>
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TransportationSection;
