import React, { useState } from "react";
import ProfileIcon from "@/public/icons/ProfileIcon.svg";
import TimerIcon from "@/public/icons/TimerIcon.svg";
import PackageIcon from "@/public/icons/PackageIcon.svg";
import RankingIcon from "@/public/icons/RankingIcon.svg";
import NotificationIcon from "@/public/icons/NotificationIcon.svg";
import LoginIcon from "@/public/icons/LoginIcon.svg";
import RegisterIcon from "@/public/icons/RegisterIcon.svg";
import GoogleIcon from "@/public/icons/GoogleIcon.svg";
import IconButton from "../Buttons/IconButton";
import Input from "../Inputs/Input";
import CheckBox from "../Inputs/CheckBox";
import { FormProvider, useForm } from "react-hook-form";
import ImageMultiSrc from "../Main/ImageMultiSrc";
import { useRouter } from "next/router";
import request from "@/utils/request";
import { toast } from "@/utils/toast";
import Cookies from "universal-cookie";
import Link from "next/link";
import { useTranslation } from "next-i18next";

const ProfileCard = (props) => {
  const { info, isLogin } = props;
  const router = useRouter();
  const methods = useForm({ mode: "onChange" });
  const { register, handleSubmit, errors, watch, setValue, formState, reset } =
    methods;
  const [loading, setLoading] = useState(false);

  const { t } = isLogin ? useTranslation("home") : useTranslation("auth");

  const handleGoogleLogin = () => {
    request(
      {
        url: "/api/web-user/auth/login/google",
        method: "POST",
      },
      {}
    )
      .then((res) => {
        window.location.assign(res.url);
      })
      .catch((err) => console.log("err", err));
  };

  const onSubmit = async (data) => {
    setLoading(true);
    request(
      {
        url: "/api/web-user/auth/login",
        method: "POST",
      },
      {
        email_phone_input: data.emailPhone,
        password: data.password,
      }
    )
      .then((res) => {
        if (data.remember_me) {
          const expiredDate = new Date();
          expiredDate.setTime(
            expiredDate.getTime() + 3 * 365 * 24 * 60 * 60 * 1000
          );
          new Cookies().set("user_token", res.data.user_token, {
            expires: expiredDate,
            path: "/",
          });
        } else {
          new Cookies().set("user_token", res.data.user_token, {
            path: "/",
          });
        }
        router.push({
          pathname: "/",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        toast.notify("Information is not correct!", "Error in Login", {
          type: 2,
        });
        setLoading(false);
      });
  };

  return (
    <>
      {isLogin ? (
        <div className="bg-black-2 p-4 w-96 h-full flex flex-col">
          <div className="flex-inline rounded-lg relative items-center">
            <ImageMultiSrc
              className="w-full h-[90px] object-cover rounded-lg"
              src={
                process.env.NEXT_PUBLIC_ROOT_URL_OF_FILES +
                "/storage/" +
                info?.cover_photo
              }
            ></ImageMultiSrc>
            <ImageMultiSrc
              className="rounded-full w-16 h-16 absolut block m-auto -mt-12 object-cover"
              src={
                process.env.NEXT_PUBLIC_ROOT_URL_OF_FILES + "/storage/" + info?.avatar
              }
            ></ImageMultiSrc>
          </div>
          <Link
            href={"/profile/dashboard"}
            className="text-white items-center inline-flex text-2xl hover:text-blue-2 transition-all w-fit group"
          >
            <ProfileIcon className="mr-2 rtl:mr-0 rtl:ml-2 group-hover:[&>path]:stroke-blue transition-all"></ProfileIcon>{" "}
            {info?.name ? info.name : "No Name"}
          </Link>

          <div className="mt-5">
            <span className="text-white text-base">
              {t("profile_point")} {info?.score?.score}/{info?.score?.total}
            </span>
            <div className="h-1 w-full bg-white rounded-lg overflow-hidden">
              <div
                className="h-1 bg-green"
                style={{
                  width: info?.score
                    ? (info?.score?.score * 100) / info?.score?.total + "%"
                    : "45%",
                }}
              ></div>
            </div>
          </div>
          <div className="mt-5 pb-5">
            <div className="flex">
              <span className="text-white items-center inline-flex text-base">
                <TimerIcon></TimerIcon>{" "}
                {info?.package?.remind == -1
                  ? t("profile_days_left_unlimited")
                  : info?.package?.remind +" "+ t("profile_days_left")}
              </span>
              <Link
                className="flex rounded-md overflow-hidden ml-auto rtl:mr-auto rtl:ml-0 text-base"
                href={"/profile/messages"}
              >
                <span className="bg-black-base px-2 py-1 relative">
                  <NotificationIcon></NotificationIcon>{" "}
                  {info?.message_unseen_count > 0 && (
                    <span className="absolute right-1 rtl:right-auto rtl:left-1 top-1 rounded-full bg-red w-1 h-1 inline-block"></span>
                  )}
                </span>
                <span className="bg-gray-light px-2 py-1 text-black">
                  {info?.message_unseen_count}
                </span>
              </Link>
            </div>
            <span className="block mt-2">
              <strong className="text-gray-light-2 text-base">
                {t("profile_purchase_request")}:{" "}
              </strong>
              <span className={`text-${info?.package?.purchase?.usage== info?.package?.purchase?.value?"red-2":"green"}`}>
                {info?.package?.purchase?.usage}/
                {info?.package?.purchase?.value}
              </span>
            </span>
            <span className="block mt-2">
              <strong className="text-gray-light-2 text-base">
                {t("profile_ready_to_ship")}:{" "}
              </strong>
              <span className={`text-${info?.package?.ready?.usage== info?.package?.ready?.value?"red-2":"green"}`}>
                {info?.package?.ready?.usage}/{info?.package?.ready?.value}
              </span>
            </span>
            <span className="block mt-2">
              <strong className="text-gray-light-2 text-base">
                {t("profile_sales_request")}:{" "}
              </strong>
              <span className={`text-${info?.package?.sales?.usage== info?.package?.sales?.value?"red-2":"green"}`}>
                {info?.package?.sales?.usage}/{info?.package?.sales?.value}
              </span>
            </span>
          </div>
          <div className="pt-4 pb-2 mt-auto border-t-2 border-gray-dark">
            <span className="text-white inline-flex items-center text-base">
              <PackageIcon className="mr-2 rtl:mr-auto rtl:ml-2"></PackageIcon>{" "}
              {info?.package?.name}
            </span>
            {/* <IconButton
              className={"float-right rtl:float-left"}
              type={"PRIMARY"}
              label={t("profile_upgrade")}
              icon={RankingIcon}
              link={"/pricing"}
            ></IconButton> */}
          </div>
        </div>
      ) : (
        <>
          <FormProvider {...methods}>
            <form className="h-full" onSubmit={handleSubmit(onSubmit)}>
              <div className="p-4 pr-0 rtl:pr-4 rtl:pl-0 w-96">
                <div className="w-full mb-4">
                  <span className="bg-black w-16 h-16 rounded-full flex items-center justify-center m-auto">
                    <ProfileIcon className="transform scale-150"></ProfileIcon>
                  </span>
                </div>
                <Input
                  placeholder={t("email_phone")}
                  wrapperClassName={"mb-5"}
                  name="emailPhone"
                  required
                  validatorRegex={
                    /(^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$)|(^\+(?:[0-9]?){6,14}[0-9]$)/i
                  }
                  errorMsg={t("email_phone_error")}
                ></Input>
                <Input
                  placeholder={t("password")}
                  name={"password"}
                  required
                  wrapperClassName={"mb-5"}
                  type={"password"}
                ></Input>
                <IconButton
                  fullWidth={true}
                  icon={LoginIcon}
                  label={t("sign_in")}
                  type={"PRIMARY"}
                  disabled={loading}
                  btnType={"submit"}
                ></IconButton>
                <div className="flex mt-2 mb-4 pb-4 border-b-[1px] border-gray-light">
                  <CheckBox
                    name="remember_me"
                    label={t("remember_me")}
                  ></CheckBox>
                  <Link
                    href="/auth/forget"
                    className="text-base font-medium text-gray-dark ml-auto rtl:mr-auto rtl:ml-0 hover:text-blue "
                  >
                    {t("forgot_password")}
                  </Link>
                </div>
                <IconButton
                  fullWidth={true}
                  icon={RegisterIcon}
                  label={t("sign_up_for_free")}
                  type={"PRIMARY"}
                  link={"/auth/register"}
                ></IconButton>
                <span className="w-full text-center border-b-[1px] border-gray-light leading-[0.01em] text-base block my-4">
                  <strong className="bg-white px-2 text-gray-dark">
                    {t("or")}
                  </strong>
                </span>
                <IconButton
                  fullWidth={true}
                  icon={GoogleIcon}
                  label={t("sign_up_with_google")}
                  type={"GOOGLE"}
                  onClick={() => handleGoogleLogin()}
                ></IconButton>
              </div>
            </form>
          </FormProvider>
        </>
      )}
    </>
  );
};

export default ProfileCard;
